.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 5px;
  border: 1px grey solid;
  border-radius: 2px;
  color: black;
}

.container input {
  width: 100%;
  min-width: 50%; 
  border: none;
  border-radius: 5px;
  padding: 5px;
  padding-left: 5px;
}

.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 2px;
  border-radius: 0px;
  background-color: '#d4d4d4';
  white-space: nowrap;
  color: '#201F1E';
  border-radius: 2px;
}
